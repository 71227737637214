<template>
    <div>
      <b-sidebar
        id="sidebar-right-confirm-unignore"
        ref="sidebar_confirm_unignore"
        :visible="showOpenConfirmUnignore"
        bg-variant="white"
        right
        backdrop
        shadow
        no-header
        @change="(val) => $emit('update:show-open-confirm-unignore', val)"
      >
        <template #default="{ hide }">
            <div class="pl-0 px-2 pt-75 pb-2" style="background: #f7f7f7; border-bottom: 1px solid #ebe9f1;">
                <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
                    Page:
                </p>
                <feather-icon
                    class="cursor-pointer float-right"
                    icon="XIcon"
                    size="20"
                    @click="hide"
                />
                <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
                    {{ idsite.title }}
                </p>
                <a :href="idsite.domain" target="_blank">
                    <span
                        class="url-header-sidebar"
                        style="text-decoration: underline; color: #A7AAB9; font-size: 15px;"
                    >
                    {{ idsite.cUrl }}
                    </span>
                </a>
            </div>
            <div
                class="b-sidebar-body"
                style="padding: 40px;"
            >
                <h3
                class="mb-75 pb-0"
                style="font-weight: 700; color: #181E38 !important;"
                >
                    Unignore Deadlink:
                </h3>
                <p style="color: gray" class="mb-25">{{ listIgnore.content }}</p>
                <p class="text-primary mb-0 mt-75">* This change will apply to all pages</p>
                <b-button
                    style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px;"
                    class="p-0 mt-1 mr-1"
                    variant="primary"
                    :disabled="processSubmit"
                    @click="proFunction"
                >
                    <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                    >Confirm</span>
                </b-button>
                <b-button
                    style="background: #F0F1F7 !important; border: 1px solid #CFD3E5 !important; width: 158px; height: 46px; border-radius: 15px;"
                    class="p-0 mt-1"
                    variant="primary"
                    @click="hide"
                >
                    <span
                        class="align-middle"
                        style="font-weight: 700; color: #A7AAB9;"
                    >No</span>
                </b-button>
            </div>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
    BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import flatPickr from 'vue-flatpickr-component'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref } from '@vue/composition-api'
  import {
    required, minLength, password, confirmed, email,
  } from 'vuelidate/lib/validators'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { mapActions } from 'vuex'
  // import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import { db, auth, firebase, dbErrors } from '@/firebase'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BFormCheckboxGroup,
      BFormGroup,
      BFormTimepicker,
      BAvatar,
      BCard,
      BCardImgLazy,
      BCardText,
      BCol,
      BRow,
      // ClaimOwnership,
      HeaderSidebar,
  
      // date
      flatPickr,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
    },
    model: {
      prop: 'showOpenConfirmUnignore',
      event: 'update:show-open-confirm-unignore',
    },
    props: ['idsite', 'showOpenConfirmUnignore', 'listIgnore'],
    data() {
      return {
        email,
        required,
        password,
        confirmed,
  
        agentesAsignados: '',
        userData: '',
        password: '',
        passwordConfirm: '',
        emailOld: '',
        showDateHour: false,
        selectedPermissions: [],
        crawls: '',
        processSubmit: false,
        fixText: '',
        catFix: '',
      }
    },
    watch: {
        showOpenConfirmUnignore(val) {
            if(val) {
                document.body.style.overflow = 'hidden'
                this.processSubmit = false
            } else {
                //   localStorage.removeItem('viewTSProMakeHistory')
                //   var fixCopilot = localStorage.getItem('fixCopilot')
                //   if(!fixCopilot) {
                //       document.body.style.overflow = 'auto'
                //   }
                this.processSubmit = false
                document.body.style.overflow = 'auto'
            }
        }
    },
    validations: {
        showOpenConfirmUnignore(val) {
            localStorage.removeItem('pro')
        }
    },
    methods: {
        submitEditar(payload) {
            this.$refs.sidebar_confirm_unignore.hide()
        },
        proFunction() {
            this.processSubmit = true
            localStorage.setItem('copilotUnignoreErr', JSON.stringify(this.listIgnore))

            setTimeout(() => {
                this.processSubmit = false
                this.$refs.sidebar_confirm_unignore.hide()
                // this.successActionsAutomated()
                localStorage.setItem('copilotListIgnoreErr', true)
            }, 7000)

            setTimeout(() => {
                localStorage.removeItem('copilotListIgnoreErr')
            }, 10000)
        },
        saveChange() {
            // console.log('first')
        },
        sendJSONFix(val, idc, siteId, siteSelected) {
            
        }
    },
    setup() {
      return {
        avatarText,
      }
    },
  }
  </script>
  
  <style>
  .b-sidebar-backdrop {
    background-color: #181E38BF !important;
    opacity: 1 !important;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  #sidebar-right-confirm-unignore {
      width: 90%;
      max-width: 593px !important;
  }
  // label {
  //   margin-bottom: 0px !important;
  //   height: 25px;
  // }
  // .col-form-label {
  //   margin-bottom: 0px !important;
  //   height: 25px;
  //   font-size: 0.857rem !important;
  // }
  // .form-control {
  //   height: 35px;
  // }
  // .v-select {
  //   height: 35px;
  //   margin-top: 0px;
  // }
  // .vs__dropdown-toggle {
  //   height: 35px;
  // }
  // .vs__selected {
  //   margin-top: 0px !important;
  // }
  </style>
  