<template>
  <div>
    <b-sidebar
      id="sidebar-right-sidebar-history"
      ref="sidebar_sidebar_history"
      :visible="showOpenSidebarHistory"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-sidebar-history', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <hr v-if="idsite.label">
        <div
          class="b-sidebar-body mt-1"
          style="padding: 0px 20px 20px 20px;"
        >
          <b-row>
            <b-col md="12">
              <p
                class="mb-25"
                style="font-weight: 700; color: #181E38; font-size: 15px;"
              >
                Page:
              </p>
              <p style="color: rgb(45, 45, 45); margin-bottom: 0px;">{{ rowError.name === 'home' ? 'Homepage' : rowError.name }}</p>
              <p style="color: #8e8a8a; margin-top: 5px;">
                <a :href="rowError.domain" target="_blank" style="text-decoration: none !important; color: #8e8a8a !important;">
                  <feather-icon
                    icon="ExternalLinkIcon"
                    style="position: relative; top: -2.5px;"
                    class="mr-25"
                  />
                  {{ rowError.domain }}
                </a>
              </p>
            </b-col>
          </b-row>
        </div>

        <div>
          <div style="vertical-align: middle;">
            <span style="color: #A7AAB9; font-size: 15px; float: right; padding-top: 3px; padding-right: 15px;">{{ rowsHistory.length }} {{ rowsHistory.length === 1 ? 'record' : 'records' }}</span>
            <h3 class="ml-2">History</h3>
          </div>
          <div
            class="mt-2 py-2"
            style="background: #7838FF0D; border-left: 4px solid #A7AAB9;"
          >
            <b-form-group
              label-for="current"
              class="color-text-grey px-2"
            >
              <template
                v-slot:label
              >
                <span style="font-size: 15px; color: #656B85;">Current {{ rowError.idc === 1 ? 'Title' : rowError.idc === 2 ? 'Description' : rowError.idc === 4 ? 'Heading H1' : rowError.idc === 5 ? 'Heading H2' : rowError.idc === 6 ? 'Word count' : rowError.idc === 13 ? 'String' : rowError.idc === 8 ? 'Image' : rowError.idc === 9 ? 'Dead link' : rowError.idc === 3 ? 'Alt tag' : rowError.idc === 14 ? 'Missing image' : rowError.idc === 11 ? 'Canonical url' : rowError.idc === 15 ? 'Suspicious text' : '' }}</span>
              </template>
              <b-form-textarea
                v-model="oldText"
                class="mt-1 "
                id="textarea-default"
                placeholder=""
                style="background: #fff; border: 1px solid #d8d6de; border-radius: 3px;"
                rows="3"
                plaintext
                v-if="rowError.idc === 13 || rowError.idc === 15"
              />
              <b-form-input
                v-model="oldText"
                class="mt-1 "
                placeholder=""
                size="lg"
                style="height: 44px;"
                readonly
                v-else
              />
            </b-form-group>
          </div>
          <div style="border-left: 4px solid #7838FF; height: 100%;">
            <b-overlay
              :show="loaderData"
              rounded="xl"
              no-fade
            >
              <vue-good-table
                ref="tableHistory"
                :columns="permission === 'view' ? columnsHistoryNoOwner : columnsHistory"
                :rows="rowsHistory"
                :fixed-header="true"
                style-class="vgt-table striped tableHistory"
                style="max-height: calc(100vh - 412px); overflow-y: auto;"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span
                    v-if="props.column.field == 'date'"
                    style=" color: #656B85; font-size: 14px;"
                  >
                    {{ props.row.date }}
                  </span>
                  <span
                    v-if="props.column.field == 'change'"
                    style=" color: #656B85; font-size: 14px;"
                  >
                    {{ props.row.change }}
                  </span>
                  <span
                    v-if="props.column.field == 'editor'"
                    style="color: #656B85; font-size: 14px; display: flex;"
                  >
                    <b-avatar
                      size="30"
                      :src="props.row.user.foto ? props.row.user.foto : ''"
                      variant="light-primary"
                      :text="avatarText(props.row.user.nombre ? (props.row.user.nombre.includes(' ') ? props.row.user.nombre : props.row.user.nombre + ' ' + props.row.user.nombre.charAt(1)) :  props.row.user.email ? props.row.user.email + ' ' + props.row.user.email.charAt(1): 'G')"
                      alt="img user"
                      :style="props.row.user.foto ? 'background: #fff !important;' : 'background: #ddd;'"
                    >
                      <feather-icon
                        v-if="!props.row.user.nombre && !props.row.user.foto && !props.row.user.email"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div class="d-sm-flex d-none user-nav ml-50">
                      <p
                        class="mb-0"
                        style="font-size: 14px; font-weight: 300 !important; margin-right: 15px; padding-top: 6px;"
                      >
                        {{ props.row.own === 'me' ? 'Me' : props.row.user.nombre ? props.row.user.nombre : props.row.user.email }}
                      </p>
                    </div>
                  </span>
                  <span
                    v-if="props.column.field == 'actions'"
                    style=" color: #656B85; font-size: 14px;"
                  >
                    <b-button
                      :style="permission === 'view' ? 'background: rgb(238, 238, 238) !important; border: 0px !important; border-radius: 3px; width: 23px; height: 23px; padding-left: 2px; padding-top: 2px; cursor: no-drop;' : 'background: rgb(238, 238, 238) !important; border: 0px !important; border-radius: 3px; width: 23px; height: 23px; padding-left: 2px; padding-top: 2px;'"
                      class="btn-icon action-history"
                      v-b-tooltip.hover.top="'Rewrite on web'"
                      :disabled="processSave"
                      @click="permission !== 'me' ? getClaim() : !hpropages.includes(rowError.domain) ? getPro() : rewriteError(props.row)"
                    >
                      <feather-icon icon="UploadIcon" style="color: #a7aab9;" size="20" class="icon-history" />
                    </b-button>
                  </span>
                </template>
              </vue-good-table>
            </b-overlay>
          </div>
        </div>
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BOverlay,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
// import moment from 'moment'
import moment from 'moment-timezone'
import store from '@/store'
import { db, firebase, auth, dbErrors } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BOverlay,
    HelpFooter,
    VueGoodTable,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenSidebarHistory',
    event: 'update:show-open-sidebar-history',
  },
  props: ['idsite', 'showOpenSidebarHistory', 'rowError', 'permission', 'changeerror', 'arraychanges', 'hpropages'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      selectedMove: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Title',
      optionsErrors: [
        'Meta title',
        'Title',
        'Descriptions',
        'Alts tag',
        'Headings H1',
        'Low word count',
        // 'External links',
      ],
      oldText: '',
      fixText: '',
      processSave: false,
      columnsHistory: [
        {
          label: 'Date',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Change',
          field: 'change',
          sortable: false,
        },
        {
          label: 'Editor',
          field: 'editor',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
        },
      ],
      columnsHistoryNoOwner: [
        {
          label: 'Date',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Change',
          field: 'change',
          sortable: false,
        },
        {
          label: 'Editor',
          field: 'editor',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
        },
      ],
      rowsHistory: [],
      arrayUsers: [],
      loaderData: false,

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
    }
  },
  watch: {
    rowError(val) {
      this.oldText = val.idc === 13 || val.idc === 15 ? val.location : val.idc === 3 ? val.alt : val.content
    },
    showOpenSidebarHistory(val) {
      // console.log(val,this.rowError)
      this.processSave = false
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }

      if(val && this.rowError) {
        this.loaderData = true
        this.rowsHistory = []
        var user = auth.currentUser
        dbErrors.collection('fixes').where('idpage', '==', this.rowError.domain).limit(1).get()
        .then(changeDocs => {
          // console.log(changeDocs.size)
          if(changeDocs.size) {
            changeDocs.forEach(changeDoc => {
              var changesArray = changeDoc.data().changes ? changeDoc.data().changes : []
              // console.log(changesArray)
              // this.rowError.domain
              var changesFilterContent = changesArray.filter(item => item.old === this.oldText)
              var changesIds = [this.rowError.idError]
              changesFilterContent.forEach(ele => {
                changesIds.push(ele.id)
              })
              // var changesFilter = changesArray.filter(item => item.url === this.rowError.domain && changesIds.includes(item.id))
              var changesFilter = changesArray.filter(item => item.url === this.rowError.domain && changesIds.includes(item.id) && item.idError == this.rowError.idc)
              this.arrayUsers = []
              changesFilter.forEach(ele => {
                // only changes by auth user
                if(ele.user === user.uid) {
                  db.collection('Users').doc(ele.user).get()
                  .then((userDoc) => {
                    // console.log(this.arrayUsers)
                    if(userDoc.exists) {
                      var filterUsersArray = this.arrayUsers.filter(item => item.uid === ele.user)
                      if(filterUsersArray.length === 0) {
                        // console.log('first')
                        var objUser = {
                          nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
                          email: userDoc.data().email,
                          uid: userDoc.data().uid,
                          foto: userDoc.data().foto
                        }
                        // console.log(this.arrayUsers)
                        var filterUsersArrayDoubleCheck = this.arrayUsers.filter(item => item.uid === ele.user)
                        filterUsersArrayDoubleCheck.length === 0 ? this.arrayUsers.push(objUser) : null

                        this.rowsHistory.push({
                          date: moment(ele.date).format("lll"),
                          dateFormat: ele.date,
                          change: ele.fix,
                          editor: objUser.foto ? objUser.foto : '',
                          actions: '',
                          user: objUser ? objUser : '',
                          own: user && user.uid ===  objUser.uid ? 'me' : 'other'
                        })
                        this.rowsHistory.sort(function(a,b) {
                          return new Date(b.dateFormat) - new Date(a.dateFormat);
                        })
                      } else {
                        // console.log('second')
                        this.rowsHistory.push({
                          date: moment(ele.date).format("lll"),
                          dateFormat: ele.date,
                          change: ele.fix,
                          editor: filterUsersArray[0].foto ? filterUsersArray[0].foto : '',
                          actions: '',
                          user: filterUsersArray[0] ? filterUsersArray[0] : '',
                          own: user && user.uid ===  filterUsersArray[0].uid ? 'me' : 'other'
                        })
                        this.rowsHistory.sort(function(a,b) {
                          return new Date(b.dateFormat) - new Date(a.dateFormat);
                        })
                      }
                    } else {
                      // console.log('third')
                      var filterUsersArrayDoubleCheck = this.arrayUsers.filter(item => item.uid === ele.user)
                      filterUsersArrayDoubleCheck.length === 0 ? this.arrayUsers.push(ele.user) : null

                      this.rowsHistory.push({
                        date: moment(ele.date).format("lll"),
                        dateFormat: ele.date,
                        change: ele.fix,
                        editor: ele.user.foto ? ele.user.foto : '',
                        actions: '',
                        user: ele.user ? ele.user : '',
                        own: user && user.uid ===  ele.user ? 'me' : 'other'
                      })
                      this.rowsHistory.sort(function(a,b) {
                        return new Date(b.dateFormat) - new Date(a.dateFormat);
                      })
                    }
                  })
                }
              })
              setTimeout(() => {
                this.loaderData = false
              }, 700)
            })
          } else {
            this.loaderData = false
          }
        })
      }
    }
  },
  validations: {
  },
  methods: {
    getPro() {
      this.$refs.sidebar_sidebar_history.hide()
      localStorage.setItem('viewTSProMake', 'true')
    },
    getClaim() {
      this.$refs.sidebar_sidebar_history.hide()
      localStorage.setItem('configRunLicense', true)
    },
    submitEditar(payload) {
      this.$refs.sidebar_sidebar_history.hide()
      this.processSave = false
    },
    reloadData() {
      this.processSave = false
      if(this.showOpenSidebarHistory && this.rowError) {
        this.loaderData = true
        this.rowsHistory = []
        var user = auth.currentUser
        // db.collection('Sites').doc(this.rowError.idsite).collection('changes').doc(this.rowError.idc.toString()).get()
        // .then(changeDoc => {
        dbErrors.collection('fixes').where('idpage', '==', this.rowError.domain).limit(1).get()
        .then(changeDocs => {
          // console.log(changeDocs.size)
          if(changeDocs.size) {
            changeDocs.forEach(changeDoc => {
              var changesArray = changeDoc.data().changes ? changeDoc.data().changes : []
              // console.log(changesArray)
              // this.rowError.domain
              var changesFilterContent = changesArray.filter(item => item.old === this.oldText)
              var changesIds = [this.rowError.idError]
              changesFilterContent.forEach(ele => {
                changesIds.push(ele.id)
              })
              // var changesFilter = changesArray.filter(item => item.url === this.rowError.domain && changesIds.includes(item.id))
              var changesFilter = changesArray.filter(item => item.url === this.rowError.domain && changesIds.includes(item.id) && item.idError == this.rowError.idc)
              this.arrayUsers = []
              changesFilter.forEach(ele => {
                // only changes by auth user
                if(ele.user === user.uid) {
                  db.collection('Users').doc(ele.user).get()
                  .then((userDoc) => {
                    // console.log(this.arrayUsers)
                    if(userDoc.exists) {
                      var filterUsersArray = this.arrayUsers.filter(item => item.uid === ele.user)
                      if(filterUsersArray.length === 0) {
                        // console.log('first')
                        var objUser = {
                          nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
                          email: userDoc.data().email,
                          uid: userDoc.data().uid,
                          foto: userDoc.data().foto
                        }
                        // console.log(this.arrayUsers)
                        var filterUsersArrayDoubleCheck = this.arrayUsers.filter(item => item.uid === ele.user)
                        filterUsersArrayDoubleCheck.length === 0 ? this.arrayUsers.push(objUser) : null

                        this.rowsHistory.push({
                          date: moment(ele.date).format("lll"),
                          dateFormat: ele.date,
                          change: ele.fix,
                          editor: objUser.foto ? objUser.foto : '',
                          actions: '',
                          user: objUser ? objUser : '',
                          own: user && user.uid ===  objUser.uid ? 'me' : 'other'
                        })
                        this.rowsHistory.sort(function(a,b) {
                          return new Date(b.dateFormat) - new Date(a.dateFormat);
                        })
                      } else {
                        // console.log('second')
                        this.rowsHistory.push({
                          date: moment(ele.date).format("lll"),
                          dateFormat: ele.date,
                          change: ele.fix,
                          editor: filterUsersArray[0].foto ? filterUsersArray[0].foto : '',
                          actions: '',
                          user: filterUsersArray[0] ? filterUsersArray[0] : '',
                          own: user && user.uid ===  filterUsersArray[0].uid ? 'me' : 'other'
                        })
                        this.rowsHistory.sort(function(a,b) {
                          return new Date(b.dateFormat) - new Date(a.dateFormat);
                        })
                      }
                    } else {
                      // console.log('third')
                      var filterUsersArrayDoubleCheck = this.arrayUsers.filter(item => item.uid === ele.user)
                      filterUsersArrayDoubleCheck.length === 0 ? this.arrayUsers.push(ele.user) : null

                      this.rowsHistory.push({
                        date: moment(ele.date).format("lll"),
                        dateFormat: ele.date,
                        change: ele.fix,
                        editor: ele.user.foto ? ele.user.foto : '',
                        actions: '',
                        user: ele.user ? ele.user : '',
                        own: user && user.uid ===  ele.user ? 'me' : 'other'
                      })
                      this.rowsHistory.sort(function(a,b) {
                        return new Date(b.dateFormat) - new Date(a.dateFormat);
                      })
                    }
                  })
                }
              })
              setTimeout(() => {
                this.loaderData = false
              }, 700)
            })
          } else {
            this.loaderData = false
          }
        })
      }
    },
    sendJSONFix(val) {
      if(this.rowError.idc !== 7) {
        const user = auth.currentUser
        if(user) {
          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            setTimeout(() => {
              dbErrors.collection('fixes_cache').doc(val).get()
              .then(docFixCache => {
                var urlTransform = new URL(this.idsite.domainFix)
                var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                const getFirstUser = userDoc.id.slice(0, 4);
                const getFirstSite = this.$route.params.id.slice(0, 4);
                // const getFirstProject = this.$route.params.project.slice(0, 3);
                var keyID = getFirstUser + getFirstSite

                var obj = {}
                obj.author = userDoc.data().email
                obj.css = ""
                obj.domain = domainConverter
                obj.key = keyID
                obj.mode = 0

                var arraychanges = docFixCache.data().changes && docFixCache.data().changes.length ? docFixCache.data().changes : []
                var pagesAlreadyProcessed = []
                arraychanges.forEach(ele => {
                  if(pagesAlreadyProcessed.filter(item => item === ele.url).length === 0) {
                    var urlTransform = new URL(ele.url)
                    // var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                    pagesAlreadyProcessed.push(ele.url)
                    var pathURLConverter = urlTransform.pathname === '/' && !ele.url.endsWith('/') ? ele.url + '/' : ele.url
                    var encodedURL = btoa(pathURLConverter)
                    obj[encodedURL] = {
                      "title": "",
                      "path": urlTransform.pathname,
                      "url": ele.url,
                      "description": "",
                      "canonical": "",
                      "siteName": "",
                      "alt": [],
                      "src": [],
                      "dlinks": [],
                      "gp": [],
                      "tags": {},
                      "tgstxt": {},
                      "findAndReplace": []
                    }

                    var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                    filterFixesOnlyEachPage.forEach(item => {
                      if(item.idError === 1) {
                        // title
                        obj[encodedURL].title = item.fix
                        // obj[encodedURL].siteName = item.fix
                      } else if(item.idError === 2) {
                        // desc
                        obj[encodedURL].description = item.fix
                      } else if(item.idError === 4) {
                        // h1
                        if(obj[encodedURL].tgstxt.h1 && obj[encodedURL].tgstxt.h1.length) {
                          obj[encodedURL].tgstxt.h1.push({
                            txt: item.old,
                            ntx: item.fix
                          })
                        } else {
                          obj[encodedURL].tgstxt.h1 = [{
                            txt: item.old,
                            ntx: item.fix
                          }]
                        }
                      } else if(item.idError === 5) {
                        // h2
                        if(obj[encodedURL].tgstxt.h2 && obj[encodedURL].tgstxt.h2.length) {
                          obj[encodedURL].tgstxt.h2.push({
                            txt: item.old,
                            ntx: item.fix
                          })
                        } else {
                          obj[encodedURL].tgstxt.h2 = [{
                            txt: item.old,
                            ntx: item.fix
                          }]
                        }
                      } else if(item.idError === 8) {
                        // imgs
                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                          obj[encodedURL].src.push({
                            url: item.old,
                            src: item.fix
                          })
                        } else {
                          obj[encodedURL].src = [{
                            url: item.old,
                            src: item.fix
                          }]
                        }

                        if(item.alt) {
                          if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                            obj[encodedURL].alt.push({
                              url: item.fix,
                              alt: item.alt
                            })
                          } else {
                            obj[encodedURL].alt = [{
                              url: item.fix,
                              alt: item.alt
                            }]
                          }
                        }
                      } else if(item.idError === 6) {
                        // lWord
                        // 08-03-2024 no available fix/edit (word count is value from crawl page)
                      } else if(item.idError === 13) {
                        // strings
                        if(item.findAndReplace) {
                          if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                            obj[encodedURL].findAndReplace.push({
                              old: item.old,
                              new: item.fix
                            })
                          } else {
                            obj[encodedURL].findAndReplace = [{
                              old: item.old,
                              new: item.fix
                            }]
                          }
                        }
                      } else if(item.idError === 3) {
                        // mAlt
                        if(item.alt) {
                          if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                            obj[encodedURL].alt.push({
                              url: item.urlImg,
                              alt: item.fix
                            })
                          } else {
                            obj[encodedURL].alt = [{
                              url: item.urlImg,
                              alt: item.fix
                            }]
                          }
                        }
                      } else if(item.idError === 9) {
                        // dLink
                        if(item.dlinks) {
                          if(obj[encodedURL].dlinks && obj[encodedURL].dlinks.length) {
                            obj[encodedURL].dlinks.push({
                              url: item.old,
                              rmp: item.fix
                            })
                          } else {
                            obj[encodedURL].dlinks = [{
                              url: item.old,
                              rmp: item.fix
                            }]
                          }
                        }
                      } else if(item.idError === 14) {
                        // missimgs
                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                          obj[encodedURL].src.push({
                            url: item.old,
                            src: item.fix
                          })
                        } else {
                          obj[encodedURL].src = [{
                            url: item.old,
                            src: item.fix
                          }]
                        }

                        if(item.alt) {
                          if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                            obj[encodedURL].alt.push({
                              url: item.fix,
                              alt: item.alt
                            })
                          } else {
                            obj[encodedURL].alt = [{
                              url: item.fix,
                              alt: item.alt
                            }]
                          }
                        }
                      } else if(item.idError === 11) {
                        // urlCan
                        obj[encodedURL].canonical = item.fix
                      } else if(item.idError === 10) {
                        // greenPadlockErrors
                      } else if(item.idError === 15) {
                        // Suspicious
                        if(item.findAndReplace) {
                          if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                            obj[encodedURL].findAndReplace.push({
                              old: item.old,
                              new: item.fix
                            })
                          } else {
                            obj[encodedURL].findAndReplace = [{
                              old: item.old,
                              new: item.fix
                            }]
                          }
                        }
                      }
                    })
                  }
                })

                var config = {
                  method: 'POST',
                  url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  data: JSON.stringify(obj),
                }

                this.axios(config)
                .then(response => {
                  dbErrors.collection('fixes_cache').doc(val).update({
                    api_file_request: obj
                  })

                  console.log('fixes file updated: '+response.data.status)
                })
                .catch(e => console.log(e.message))
              })
            }, 2000)
          })
        }
      }
    },
    rewriteError(payload) {
      // console.log(payload.change)
      this.fixText = ''
      this.fixText = payload.change
      this.processSave = true
      var user = auth.currentUser
      // save changes
      db.collection('Users').doc(user.uid).get()
      .then(userDoc => {
        var authUser = {
          nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
          email: userDoc.data().email,
          uid: userDoc.data().uid,
          foto: userDoc.data().foto
        }

        // console.log(this.rowError.idsite,this.rowError.idpage,this.rowError.idc.toString())
        db.collection('Sites').doc(this.rowError.idsite).get()
        .then(siteDoc => {
          var listCounterPage = siteDoc.data().errorsPages && siteDoc.data().errorsPages.length ? siteDoc.data().errorsPages : []
          var filterCounterPage = listCounterPage.filter(eleErr => eleErr.url === this.rowError.domain)
          var docsObjErrors = siteDoc.data().errorsTypePage && siteDoc.data().errorsTypePage.length >= 1 ? siteDoc.data().errorsTypePage : []
          var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === this.rowError.domain)
          var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== this.rowError.domain)
          if(this.rowError.exist === true) {
            filterObjErrorsFilter.forEach(elePage => {
              if(elePage.errors && elePage.errors.length) {
                elePage.errors.forEach(eleCounter => {
                  if(this.rowError.idc === 1) {
                    if(eleCounter.type === 'title') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 2) {
                    if(eleCounter.type === 'desc') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 4) {
                    if(eleCounter.type === 'h1') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 5) {
                    if(eleCounter.type === 'h2') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 8) {
                    if(eleCounter.type === 'imgs') {
                      // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                      eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                    }
                  } else if(this.rowError.idc === 6) {
                    if(eleCounter.type === 'lWord') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 13) {
                    if(eleCounter.type === 'strings') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 3) {
                    if(eleCounter.type === 'mAlt') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 9) {
                    if(eleCounter.type === 'dLink') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 14) {
                    if(eleCounter.type === 'imgs') {
                      // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                      eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                    }
                  } else if(this.rowError.idc === 11) {
                    if(eleCounter.type === 'urlCan') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 10) {
                    // if(eleCounter.type === 'urlCan') {
                    //   eleCounter.counter -= 1
                    // }
                  } else if(this.rowError.idc === 15) {
                    if(eleCounter.type === 'strings') {
                      eleCounter.counter -= 1
                    }
                  }
                })
              }
            })

            var counterFixErr = 0
            filterObjErrorsFilter.forEach(eleSiteTypeError => {
              if(counterFixErr === 0) {
                counterFixErr += 1
                filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
              }
            })

            db.collection('Sites').doc(this.rowError.idsite).update({
              errorsTypePage: filterObjNoSiteErrorsFilter
            })
          }

          if(this.rowError.idc === 1) {
            var arrayPages = siteDoc.data().pages && siteDoc.data().pages.length >= 1 ? siteDoc.data().pages : []
            var filterPageTitle = arrayPages.filter(ele => ele.url === this.rowError.domain && ele.status !== null)
            var filterNoPageTitle = arrayPages.filter(ele => ele.url !== this.rowError.domain)
            if(filterPageTitle.length >= 1) {
              var counterPageT = 0
              filterPageTitle.forEach(ele => {
                if(counterPageT === 0) {
                  counterPageT += 1
                  ele.title = this.fixText
                  filterNoPageTitle.push(ele)
                }
              })

              db.collection('Sites').doc(this.rowError.idsite).update({
                pages: filterNoPageTitle
              })
              console.log('updated title')
            }
          }

          dbErrors.collection('fixes').where('idpage', '==', this.rowError.domain).limit(1).get()
          .then(docPageFixCollection => {
          dbErrors.collection('fixes_cache').where('SiteID', '==', this.rowError.idsite).limit(1).get()
          .then(docPageFixCacheCollection => {
            var idDocPageFixCacheColl = ''
            var changesFixesCache = []
            docPageFixCacheCollection.forEach(docPageFixCacheColl => {
              idDocPageFixCacheColl = docPageFixCacheColl.id
              changesFixesCache = docPageFixCacheColl.data().changes
            })

            // exist already fix document
            if(docPageFixCollection.size >= 1) {
              var idDocPageFixColl = ''
              var counterErrorFixes = 0
              var arrayFixesDomain = []
              var originalTextFix = ''
              docPageFixCollection.forEach(docPageFixColl => {
                idDocPageFixColl = docPageFixColl.id
                if(docPageFixColl.data().changes && docPageFixColl.data().changes.length >= 1) {
                  docPageFixColl.data().changes.forEach(ele => {
                    arrayFixesDomain.push(ele)
                    if(ele.idfix === 1) {
                      originalTextFix = ele.old
                    }
                  })
                }
              })
              counterErrorFixes = (arrayFixesDomain.filter(ele => ele.idError === this.rowError.idc).length) + 1
              if(arrayFixesDomain.filter(ele => ele.idError === this.rowError.idc).length === 0) {
                originalTextFix = this.oldText
              }

              dbErrors.collection('Errors').where('idpage', '==', this.rowError.domain).limit(1).get()
              .then(docPageError => {
                var idDocError = ''
                var changesDocError = []
                var listErrors = []
                var changesPage = []
                docPageError.forEach(docError => {
                  idDocError = docError.id
                  changesDocError = docError.data().changes ? docError.data().changes : []
                  listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                  changesPage = docError.data().changes ? docError.data().changes : []
                })

                var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.rowError.domain)
                if(!changesPage.length) {
                  // update asignated pro and sizes
                  db.collection('Sites').doc(this.rowError.idsite).update({
                    asignatedProPages: true,
                    pageSizes: filterSizePageExclude
                  })

                  // update error status and cont/est
                  var filterErr = []
                  if(this.rowError.idc === 8) {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          var originalIE = ele.iE
                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }

                          if(ele.idc === 3) {
                            ele.est = this.fixTextAlt
                            ele.cont = this.fixText
                            ele.sta = 200
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                            if(originalIE) {
                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                })
                              }
                            }
                          } else {
                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                          }
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  } else {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }
                          this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                          this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                          ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  }

                  // update error counter category
                  if(this.rowError.idc === 1) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 2) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 4) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 5) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 8) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 6) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 13) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 3) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 9) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 14) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 11) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 10) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 15) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                    //   })
                    // }
                  }
                  
                  // update error counters by page
                  if(filterCounterPage.length) {
                    filterErr.forEach(ele => {
                      if(ele.counter) {
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          ele.counter = ele.counter - 1
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                        })
                      }
                    })
                  }

                  if(changesPage.length) {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  } else {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  }

                  setTimeout(() => {
                    this.$emit('update:changeerror', true)
                    var arrayChan = []
                    this.arraychanges.forEach(ele => {
                      arrayChan.push(ele)
                    })
                    arrayChan.push(this.rowError.idError)
                    this.$emit('update:arraychanges', arrayChan)
                    this.sendJSONFix(idDocPageFixCacheColl)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Change applied`,
                        icon: 'BellIcon',
                        variant: 'success',
                      },
                    })
                    
                    this.processSave = false
                    this.reloadData()
                    // this.fixText = ''
                    this.oldText = payload.change
                  }, 1700)
                } else {
                  // update asignated pro
                  db.collection('Sites').doc(this.rowError.idsite).update({
                    asignatedProPages: true,
                    pageSizes: filterSizePageExclude
                  })

                  // update error status and cont/est
                  var filterErr = []
                  if(this.rowError.idc === 8) {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          var originalIE = ele.iE
                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }

                          if(ele.idc === 3) {
                            ele.est = this.fixTextAlt
                            ele.cont = this.fixText
                            ele.sta = 200
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                            if(originalIE) {
                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                })
                              }
                            }
                          } else {
                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                          }
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  } else {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }
                          this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                          this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                          ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  }

                  // update error counter category
                  if(this.rowError.idc === 1) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 2) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 4) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 5) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 8) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 6) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 13) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 3) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 9) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 14) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 11) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 10) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 15) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                    //   })
                    // }
                  }
                  
                  // update error counters by page
                  if(filterCounterPage.length) {
                    filterErr.forEach(ele => {
                      if(ele.counter) {
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          ele.counter = ele.counter - 1
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                        })
                      }
                    })
                  }

                  if(changesPage.length) {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  } else {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix ? originalTextFix : '',
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  }

                  setTimeout(() => {
                    this.$emit('update:changeerror', true)
                    var arrayChan = []
                    this.arraychanges.forEach(ele => {
                      arrayChan.push(ele)
                    })
                    arrayChan.push(this.rowError.idError)
                    this.$emit('update:arraychanges', arrayChan)
                    this.sendJSONFix(idDocPageFixCacheColl)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Change applied`,
                        icon: 'BellIcon',
                        variant: 'success',
                      },
                    })
                    
                    this.processSave = false
                    this.reloadData()
                    // this.fixText = ''
                    this.oldText = payload.change
                  }, 1700)
                }

                dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                  changes: firebase.firestore.FieldValue.arrayUnion({
                    id: this.rowError.idError,
                    idError: this.rowError.idc,
                    typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                    old: originalTextFix ? originalTextFix : '',
                    fix: this.fixText,
                    url: this.rowError.domain ? this.rowError.domain : '',
                    date: Date.now(),
                    user: authUser.uid,
                    idfix: counterErrorFixes
                  }),
                  has_fix: true,
                  updated: Date.now()
                })

                var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                if(filterchangesFixesCache.length === 0) {
                  dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                    changes: firebase.firestore.FieldValue.arrayUnion({
                      id: this.rowError.idError,
                      idError: this.rowError.idc,
                      typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                      old: originalTextFix ? originalTextFix : '',
                      fix: this.fixText,
                      url: this.rowError.domain ? this.rowError.domain : '',
                      date: Date.now(),
                      // user: authUser,
                      idfix: counterErrorFixes
                    }),
                    has_fix: true,
                    updated: Date.now()
                  })
                } else {
                  var filterNochangesFixesCache = []
                  changesFixesCache.forEach(ele => {
                    // console.log(ele)
                    if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                      // filterNochangesFixesCache.push(ele)
                      // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                    } else {
                      // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                      filterNochangesFixesCache.push(ele)
                    }
                  })
                  // console.log(filterNochangesFixesCache, changesFixesCache)
                  filterNochangesFixesCache.push({
                    id: this.rowError.idError,
                    idError: this.rowError.idc,
                    typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                    old: originalTextFix ? originalTextFix : '',
                    fix: this.fixText,
                    url: this.rowError.domain ? this.rowError.domain : '',
                    date: Date.now(),
                    // user: authUser,
                    idfix: counterErrorFixes
                  })

                  dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                    changes: filterNochangesFixesCache,
                    has_fix: true,
                    updated: Date.now()
                  })
                }
              })
            } else {
              if(!idDocPageFixCacheColl) {
                // doesnt exist fix document
                dbErrors.collection('fixes_cache').add({
                  SiteID: this.rowError.idsite,
                  // idpage: this.rowError.domain,
                  owner: authUser.uid,
                  ownerEmail: authUser.email,
                  changes: [],
                  updated: Date.now()
                }).then((docRefFixCacheColl) => {
                  
                  dbErrors.collection('fixes').add({
                    SiteID: this.rowError.idsite,
                    idpage: this.rowError.domain,
                    changes: [],
                    updated: Date.now()
                  }).then((docRefFixColl) => {
                    var idDocPageFixColl = docRefFixColl.id
                    var idDocPageFixCacheColl = docRefFixCacheColl.id

                    dbErrors.collection('Errors').where('idpage', '==', this.rowError.domain).limit(1).get()
                    .then(docPageError => {
                      var idDocError = ''
                      var changesDocError = []
                      var listErrors = []
                      var changesPage = []
                      docPageError.forEach(docError => {
                        idDocError = docError.id
                        changesDocError = docError.data().changes ? docError.data().changes : []
                        listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                        changesPage = docError.data().changes ? docError.data().changes : []
                      })

                      var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                      var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.rowError.domain)
                      if(!changesPage.length) {
                        // update asignated pro and sizes
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          
                          this.processSave = false
                          this.reloadData()
                          // this.fixText = ''
                          this.oldText = payload.change
                        }, 1700)
                      } else {
                        // update asignated pro
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          
                          this.processSave = false
                          this.reloadData()
                          // this.fixText = ''
                          this.oldText = payload.change
                        }, 1700)
                      }

                      dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                        changes: [{
                          id: this.rowError.idError,
                          idError: this.rowError.idc,
                          typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                          old: this.oldText,
                          fix: this.fixText,
                          url: this.rowError.domain ? this.rowError.domain : '',
                          date: Date.now(),
                          user: authUser.uid,
                          idfix: 1
                        }],
                        has_fix: true,
                        updated: Date.now()
                      })

                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                        changes: [{
                          id: this.rowError.idError,
                          idError: this.rowError.idc,
                          typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                          old: this.oldText,
                          fix: this.fixText,
                          url: this.rowError.domain ? this.rowError.domain : '',
                          date: Date.now(),
                          // user: authUser,
                          idfix: 1
                        }],
                        has_fix: true,
                        updated: Date.now()
                      })
                    })
                  })
                })
              } else {
                // doesnt exist fix document 
                  dbErrors.collection('fixes').add({
                    SiteID: this.rowError.idsite,
                    idpage: this.rowError.domain,
                    changes: [],
                    updated: Date.now()
                  }).then((docRefFixColl) => {
                    var idDocPageFixColl = docRefFixColl.id
                    // var idDocPageFixCacheColl = docRefFixCacheColl.id

                    dbErrors.collection('Errors').where('idpage', '==', this.rowError.domain).limit(1).get()
                    .then(docPageError => {
                      var idDocError = ''
                      var changesDocError = []
                      var listErrors = []
                      var changesPage = []
                      docPageError.forEach(docError => {
                        idDocError = docError.id
                        changesDocError = docError.data().changes ? docError.data().changes : []
                        listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                        changesPage = docError.data().changes ? docError.data().changes : []
                      })

                      var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                      var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.rowError.domain)
                      if(!changesPage.length) {
                        // update asignated pro and sizes
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          
                          this.processSave = false
                          this.reloadData()
                          // this.fixText = ''
                          this.oldText = payload.change
                        }, 1700)
                      } else {
                        // update asignated pro
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          
                          this.processSave = false
                          this.reloadData()
                          // this.fixText = ''
                          this.oldText = payload.change
                        }, 1700)
                      }

                      dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                        changes: [{
                          id: this.rowError.idError,
                          idError: this.rowError.idc,
                          typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                          old: this.oldText,
                          fix: this.fixText,
                          url: this.rowError.domain ? this.rowError.domain : '',
                          date: Date.now(),
                          user: authUser.uid,
                          idfix: 1
                        }],
                        has_fix: true,
                        updated: Date.now()
                      })

                      if(changesFixesCache.length >= 1) {
                        var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                        if(filterchangesFixesCache.length === 0) {
                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                            changes: firebase.firestore.FieldValue.arrayUnion({
                              id: this.rowError.idError,
                              idError: this.rowError.idc,
                              typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                              old: this.oldText,
                              fix: this.fixText,
                              url: this.rowError.domain ? this.rowError.domain : '',
                              date: Date.now(),
                              // user: authUser,
                              idfix: 1
                            }),
                            has_fix: true,
                            updated: Date.now()
                          })
                        } else {
                          var filterNochangesFixesCache = []
                          changesFixesCache.forEach(ele => {
                            // console.log(ele)
                            if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                              // filterNochangesFixesCache.push(ele)
                              // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                            } else {
                              // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                              filterNochangesFixesCache.push(ele)
                            }
                          })
                          // console.log(filterNochangesFixesCache, changesFixesCache)
                          filterNochangesFixesCache.push({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            // user: authUser,
                            idfix: 1
                          })

                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                            changes: filterNochangesFixesCache,
                            has_fix: true,
                            updated: Date.now()
                          })
                        }
                      } else {
                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            // user: authUser,
                            idfix: 1
                          }],
                          has_fix: true,
                          updated: Date.now()
                        })
                      }
                    })
                  })
                // })
              }
            }
          })
          })
        })
      })
    }
  },
  created() {
    // moment.tz.setDefault('America/New_York');
    // moment.utc()
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-sidebar-history {
  width: 90%;
  max-width: 1000px !important;
}
.action-history .icon-history:hover{
  color: #ca34ff !important;
}
.tableHistory thead tr th {
  background: #fff !important;
  font-weight: 500 !important;
}
// .user-dropdown-text::after {
//   display: inline-block;
//   margin-left: .255em;
//   vertical-align: .255em;
//   // content: "";
//   // border-top: .3em solid;
//   // border-right: .3em solid transparent;
//   // border-bottom: 0;
//   // border-left: .3em solid transparent;
//   // position: absolute;
//   margin-top: 10px;
// }
</style>